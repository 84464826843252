<template>
  <div>
    <v-col cols="12" v-if="loadingPage">
      <SkeletonLoaders type="card-heading, list-item-three-line" />
    </v-col>
    <div class="payments__page" v-else>
      <!--      <Redirect class="" title="Settings" route="website.settings" />-->
      <v-row no-gutters>
        <v-col class="page-header" cols="12">
          <h2 class="page-title">Payout</h2>
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12" md="4">
          <h2>Balance</h2>
          <p>{{ balanceText }}</p>
        </v-col>
        <v-col cols="12" md="8">
          <v-card>
            <v-card-text class="pb-3">
              <v-row no-gutters class="mb-2">
                <v-col xs="6">Available to payout:</v-col>
                <v-col xs="6" class="text-right">${{ currency(balance.availableToPayout || 0) }}</v-col>
              </v-row>
              <!-- <v-row no-gutters class="mb-2">
                <v-col xs="6">Available soon:</v-col>
                <v-col xs="6" class="text-right">${{ currency(balance.availableSoon || 0) }}</v-col>
              </v-row> -->
              <!-- <v-row no-gutters class="mb-2">
                <v-col xs="6">Today profits:</v-col>
                <v-col xs="6" class="text-right">${{ currency(parseFloat(balance.todayProfit || 0)) }}</v-col>
              </v-row> -->
              <v-row no-gutters class="mb-3">
                <v-col xs="6">Total amount paid:</v-col>
                <v-col xs="6" class="text-right">
                  <strong>${{ currency(balance.totalAmountPaid) }}</strong>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-2">
                <v-col xs="6">Total hold:</v-col>
                <v-col xs="6" class="text-right">${{ currency(balance.totalHold || 0) }} </v-col>
              </v-row>
              <v-row no-gutters class="mb-3">
                <v-col xs="6"><strong>Total profits:</strong></v-col>
                <v-col xs="6" class="text-right">
                  <strong
                    >${{ currency(parseFloat(balance.totalProfit || 0) + parseFloat(balance.totalHold || 0)) }}</strong
                  >
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions class="px-4 pb-4">
              <v-btn color="primary" v-if="showRequestButton" @click="showRequest" class="mr-2">
                <v-icon class="mr-2">fas fa-plus</v-icon>
                Request payout
              </v-btn>
              <v-btn @click="showPayouts">
                <v-icon class="mr-2">fas fa-list</v-icon>
                View payouts
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12" md="4">
          <h2>Payout</h2>
          <p>{{ payoutText }}</p>
        </v-col>
        <v-col cols="12" md="8">
          <v-card class="mb-3">
            <v-card-text>
              <v-row>
                <v-col md="12" class="py-0">
                  <h3>Bank Account</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="py-0">
                  <label>Account name</label>
                  <v-text-field hide-details="" v-model="creditcard.paymentAttributes.cardName" />
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col md="6" class="py-0">
                      <label>Card number</label>
                      <v-text-field hide-details="" v-model="creditcard.paymentAttributes.cardNumber" />
                    </v-col> -->
                <v-col md="12" class="py-0">
                  <label>Account number</label>
                  <v-text-field hide-details="" v-model="creditcard.paymentAttributes.accountNumber" />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="py-0">
                  <label>Bank name</label>
                  <v-text-field
                    hide-details=""
                    placeholder="VCB, BIDV, TCB, ..."
                    v-model="creditcard.paymentAttributes.bankName"
                  />
                </v-col>
              </v-row>
              <v-row class="pt-5 ml-0">
                <v-btn color="primary" @click="submitAccount(creditcard)">SAVE</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mb-3">
            <v-card-text>
              <h3 class="mb-3">Account information</h3>
              <label class="font-weight-bold">Payoneer account</label>
              <div class="d-flex align-center">
                <v-text-field hide-details="" v-model="payoneer.paymentValue" />
                <v-btn class="ml-4" :height="36" color="primary" @click="submitAccount(payoneer)">SAVE</v-btn>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="mb-3" style="display: none">
            <v-card-text>
              <v-row no-gutters>
                <v-col sm="10">
                  <h3>PayPal account</h3>
                  <v-text-field hide-details="" v-model="paypal.paymentValue" />
                </v-col>
                <v-col sm="2" class="text-right" style="padding-top: 24px;">
                  <v-btn color="primary" @click="submitAccount(paypal)">SAVE</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mb-3" style="display: none">
            <v-card-text>
              <v-row no-gutters>
                <v-col sm="10">
                  <h3>PingPong account</h3>
                  <v-text-field hide-details="" v-model="pingpong.paymentValue" />
                </v-col>
                <v-col sm="2" class="text-right" style="padding-top: 24px;">
                  <v-btn color="primary" @click="submitAccount(pingpong)">SAVE</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12" md="4">
          <h2>Top-ups</h2>
          <p>
            Top-ups are funds you add to your LatteHub Balance.
          </p>
        </v-col>
        <v-col cols="12" md="8">
          <v-card>
            <v-card-text>
              <h3 class="mb-1">Auto Top-up</h3>
              <p>
                The features requiring credit might be stopped when the balance becomes insufficient. By enabling the
                Auto Recharge option we will automatically add more credits if your balance falls below a certain
                threshold.
              </p>
              <div class="d-flex">
                <v-btn color="primary" class="mr-3" @click="$router.push({ name: 'website.topUp' })">Top up</v-btn>
                <v-btn @click="$router.push({ name: 'website.topUp.history' })">View top-ups</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <v-dialog v-model="requestModal" width="480">
        <v-card>
          <v-card-title>Request payout</v-card-title>
          <v-card-text>
            <v-row>
              <v-col xs="8">Available to payout:</v-col>
              <v-col xs="4" class="text-right">
                <strong>${{ currency(balance.availableToPayout) }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" v-if="requestError && requestError != ''" style="font-style: italic; color: #c00;">
                {{ requestError }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" class="pb-1">
                <label>Method</label>
                <v-select v-model="payout.payoutMethod" :items="payoutMethods" />
              </v-col>
              <v-col sm="12">
                <label>Requested amount</label>
                <v-text-field type="number" hide-details="" v-model.number="payout.amount" suffix="$" />
              </v-col>
              <v-col sm="12" v-if="false">
                <label>Note</label>
                <v-text-field hide-details="" v-model.trim="payout.note" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-block text-right">
            <v-btn @click="requestModal = false">CLOSE</v-btn>
            <v-btn color="primary" @click="submitRequest">REQUEST</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="payoutModal" width="1024px">
        <v-card relative>
          <v-card-title>Payouts</v-card-title>
          <v-card-text class="px-0">
            <v-simple-table fixed-header>
              <thead>
                <tr>
                  <th>CREATED DATE</th>
                  <th>BILL NAME</th>
                  <th>METHOD</th>
                  <!-- <th>NOTE</th> -->
                  <th>AMOUNT</th>
                  <th>STATUS</th>
                  <th>APPROVED DATE</th>
                  <th>NOTE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in payouts" :key="item._id">
                  <td>{{ formatDate(item.createdDate) }}</td>
                  <td>{{ item.billName }}</td>
                  <td>{{ item.payoutMethod.paymentType == 'creditcard' ? 'Bank' : item.payoutMethod.paymentType }}</td>
                  <td>${{ item.amount }}</td>
                  <td>
                    <span v-if="item.status == 0">{{ statusName(item.status) }}</span>
                    <span style="color: green" v-if="item.status == 1">{{ statusName(item.status) }}</span>
                    <span style="color: red" v-if="item.status == 2">{{ statusName(item.status) }}</span>
                  </td>
                  <td>{{ formatDate(item.approvedDate) }}</td>
                  <td>{{ item.note ? item.note : '' }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-text class="text-right">
            <v-pagination
              v-model="filter.page"
              :length="filter.totalPage"
              :total-visible="7"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
            ></v-pagination>
          </v-card-text>
          <v-card-actions class="d-block text-right">
            <v-btn @click="payoutModal = false">CLOSE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
// import Redirect from '@/components/RedirectTo.vue';
import { payoutService } from '@/apis/payout';
import { mapState } from 'vuex';
import SkeletonLoaders from '@/components/SkeletonLoaders.vue';

export default {
  components: {
    // Redirect,
    SkeletonLoaders,
  },
  data() {
    let scheduleText = ['Payout requests are usually processed within one working day.'];
    scheduleText.push('Your first payout might take up to 5 days because of the initial review process.');
    scheduleText.push('When they are approved, the funds will reach your payment account within 2 to 4 hours.');
    scheduleText.push('Please note that if you request payouts during the working time,');
    scheduleText.push('they will be processed faster.');
    let texts = ['Balance is your credit account.'];
    texts.push('The amount of this balance is applied for using Service,');
    texts.push('paying Subscription fee and Transaction fee.');
    let payoutText = ['The sales collected via Balance will be paid out to you after subtracting the payment'];
    payoutText.push('for service and complement for the negative amount.');
    return {
      requestError: null,
      scheduleText: scheduleText.join(' '),
      balanceText: texts.join(' '),
      payoutText: payoutText.join(' '),
      payments: [],
      balance: {},
      paypal: {},
      payoneer: {},
      pingpong: {},
      creditcard: { paymentAttributes: {} },
      payout: {},
      paymentTypes: [
        { text: 'Payoneer', value: 'payoneer' },
        { text: 'PayPal', value: 'paypal' },
        { text: 'PingPong', value: 'pingpong' },
        { text: 'Bank', value: 'creditcard' },
      ],
      payouts: [],
      requestModal: false,
      payoutModal: false,
      listPayout: [],
      card: {
        cardName: '',
        accountNumber: '',
        bankName: '',
        cardNumber: '',
      },
      showRequestButton: false,
      timer: null,
      filter: {
        page: 1,
        limit: 10,
        status: -1,
        totalPage: 0,
      },
      loadingPage: false,
    };
  },
  computed: {
    payoutMethods() {
      if (!this.payments) return [];
      let types = _.mapValues(_.groupBy(this.paymentTypes, 'value'), arr => arr[0].text);
      return this.payments.map(item => {
        return {
          text: types[item.paymentType] || 'n/a',
          value: item._id,
        };
      });
    },
    balanceTotal() {
      if (!this.balance || !this.balance.availableToPayout) return '0.00';
      return Number(this.balance.availableToPayout) + Number(this.balance.availableSoon);
    },
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  watch: {
    'filter.page': {
      handler() {
        this.loadPayouts();
      },
    },
  },
  methods: {
    currency(val) {
      val = Math.floor(Number(val) * 100) / 100;
      return val.toLocaleString();
    },
    statusName(val) {
      if (val == 0) return 'Pending';
      if (val == 1) return 'Approved';
      if (val == 2) return 'Rejected';
      return 'n/a';
    },
    formatDate(val) {
      if (val) return moment(val).format('DD-MM-YYYY HH:mm');
      return '';
    },
    async loadPayouts() {
      try {
        let query = `&page=${this.filter.page}&limit=${this.filter.limit}&status=${this.filter.status}`;
        let res = await payoutService.getPayouts(query);
        let d = res.data;
        this.payouts = d.payouts;
        let pagination = d.pagination;
        this.filter.totalPage = parseInt(pagination.total / pagination.limit) + 1;
      } catch (err) {
        console.log(err);
        this.payouts = [];
      }
    },
    async loadData() {
      await this.loadAccounts();
      await this.loadBalance();
    },
    async loadAccounts() {
      try {
        let res = await payoutService.getMethods();
        this.payments = res.data || [];
      } catch (err) {
        console.log(err);
        this.payments = [];
      }
      this.loadAccount('paypal');
      this.loadAccount('pingpong');
      this.loadAccount('creditcard');
      this.loadAccount('payoneer');
    },
    async loadBalance() {
      try {
        let res = await payoutService.getBalance();
        this.balance = res.data || {};
        this.showRequestButton = this.balance.enableRequest || false;
        this.$store.commit('pagePreference/setBalance', res.data?.availableToPayout || 0);
      } catch (err) {
        console.log(err);
        this.methods = {};
      }
    },
    loadAccount(type) {
      let item;
      for (let it of this.payments) {
        if (it.paymentType == type) {
          item = it;
          break;
        }
      }
      if (item) {
        let attrs = item.paymentAttributes || {};
        this[type] = {
          _id: item._id,
          paymentType: item.paymentType || 'paypal',
          paymentValue: item.paymentValue || '',
          paymentAttributes: {
            cardName: attrs.cardName || '',
            cardNumber: attrs.cardNumber || '',
            accountNumber: attrs.accountNumber || '',
            bankName: attrs.bankName || '',
          },
        };
      } else {
        this[type] = {
          paymentType: type,
          paymentValue: '',
          paymentAttributes: {
            cardName: '',
            cardNumber: '',
            accountNumber: '',
            bankName: '',
          },
        };
      }
    },
    showRequest() {
      this.requestError = null;
      this.payout = {
        billName: '',
        amount: 0,
        payoutMethod: '',
        note: '',
      };
      this.requestModal = true;
    },
    showPayouts() {
      this.payoutModal = true;
      this.loadPayouts();
    },
    async submitAccount(model) {
      try {
        let res = model._id
          ? await payoutService.updateMethod(model._id, model)
          : await payoutService.createMethod(model);
        this.loadData();
      } catch (error) {}
    },
    async submitRequest() {
      try {
        //this.payout.store = '123';
        this.payout.status = 0;
        let res = await payoutService.createPayoutRequest(this.payout);
        let { success, msg } = res.data;
        if (success) {
          this.requestError = null;
          this.requestModal = false;
          this.loadData();
        } else {
          this.requestError = msg;
        }
      } catch (err) {
        if (err.response && err.response.data) {
          this.requestError = err.response.data.msg || err.response.data.message;
        } else {
          this.requestError = err.message;
        }
      }
    },
    async getTime() {
      let vm = this;
      if (vm.timer) clearTimeout(vm.timer);

      // console.log('--> enable payout');
      try {
        let res = await payoutService.getBalance();
        vm.balance = res.data || {};
        vm.showRequestButton = vm.balance.enableRequest || false;
      } catch (err) {
        console.log(err);
      }

      vm.timer = setTimeout(() => {
        vm.getTime();
      }, 60000);
    },
  },

  mounted() {
    this.loadData();
    this.getTime();
  },
};
</script>

<style scoped>
.v-text-field__prefix {
  padding-right: 0px !important;
  padding-left: 16px !important;
}
</style>
