import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/payout/card/';

function get() {
  return storeRequest.get(path);
}

function create(data) {
  return storeRequest.post(path, data);
}

function update(id, data) {
  return storeRequest.patch(path + id, data);
}
function remove(id) {
  return storeRequest.delete(path + id);
}

function createMethod(data) {
  return storeRequest.post('/stores/payout/config', data);
}

function getMethods() {
  return storeRequest.get('/stores/payout/config');
}

function updateMethod(id, data) {
  return storeRequest.patch('/stores/payout/config/' + id, data);
}

function createPayoutRequest(data) {
  return storeRequest.post('/stores/payout/v2/payout-request', data);
}

function getPayouts(query) {
  return storeRequest.get(`/stores/payout/request-payouts?${query}`);
}

function getBalance() {
  return storeRequest.get('/stores/payout/v2/balance');
}

export const payoutService = {
  createMethod,
  getMethods,
  updateMethod,
  createPayoutRequest,
  getPayouts,
  getBalance,
  create,
  update,
  get,
  remove,
};
